import { Alert } from '@material-ui/lab';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { useScriptInject } from '../shared/helpers';
import { useAlert } from '../shared/hooks';

let alreadyLoaded = false;

const Recon = () => {
  const [loaded, setLoaded] = useState(alreadyLoaded);
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();
  const { showAlert } = useAlert(loaded, MODULES.RECON);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('recon-bundle');

  setStartHandleTime();

  useEffect(() => {
    if (loaded && window.RECON_UI) {
      window.RECON_UI.render(document.getElementById('recon'));
    }
    return () => {
      window.RECON_UI?.root?.unmount();
    };
  }, [loaded]);

  const handleScriptInjectCB = useCallback((_newState: any, addedTags: any) => {
    handleScriptInject(addedTags, toLowerCase(MODULES.RECON), () => {
      setLoaded(true);
      logHandleTime();
      alreadyLoaded = true;
      if (window.RECON_UI) {
        window.RECON_UI.render(document.getElementById('recon'));
      }
    });
  }, []);

  const baseUrl = getApiUrl(Service.Recon);

  return (
    <>
      <Helmet
        script={
          loaded
            ? []
            : [
                {
                  src: ' https://code.jquery.com/jquery-3.2.1.slim.min.js',
                },
                {
                  src: 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js',
                },
                {
                  src: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js',
                },
                {
                  src: `${baseUrl}/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css"
        />
        <link rel="stylesheet" href={`${baseUrl}/static/css/main.css`} />
      </Helmet>
      <div id="recon" className="Recon" />
      {showAlert && (
        <Alert className="AlertVpn" severity="warning">
          VPN is needed to access <strong>Recon</strong>
        </Alert>
      )}
    </>
  );
};

export default Recon;
