import { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { getReleasePostfix, useScriptInject } from '../shared/helpers';

const CorporateActionsId = 'corporate-actions';
let loaded = false;

const CorporateActions = () => {
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'corporate-actions-bundle',
  );

  setStartHandleTime();

  useEffect(() => {
    if (loaded && window.CORPORATE_ACTIONS_UI) {
      window.CORPORATE_ACTIONS_UI.render(
        document.getElementById(CorporateActionsId),
      );
    }
    return () => {
      window.CORPORATE_ACTIONS_UI?.root?.unmount();
    };
  }, []);

  const handleScriptInjectCB = useCallback((newState: any, addedTags: any) => {
    handleScriptInject(
      addedTags,
      toLowerCase(MODULES.CORPORATE_ACTIONS_UI),
      () => {
        logHandleTime();
        loaded = true;
        if (window.CORPORATE_ACTIONS_UI) {
          window.CORPORATE_ACTIONS_UI.render(
            document.getElementById(CorporateActionsId),
          );
        }
      },
    );
  }, []);

  const baseUrl = getApiUrl(Service.CAS);
  const releasePostfix = useMemo(() => getReleasePostfix(), []);

  return (
    <>
      <Helmet
        script={
          loaded
            ? []
            : [
                {
                  src: `${baseUrl}${releasePostfix}/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href={`${baseUrl}${releasePostfix}/static/css/main.css`}
        />
      </Helmet>
      <div id={CorporateActionsId} />
    </>
  );
};

export default CorporateActions;
